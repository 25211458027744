import { useState } from "react"

export const AGB = (props) => {
  const [isOpened, setIsOpened] = useState(false)
  
  function toggle() {
    setIsOpened(wasOpened => !wasOpened);
  }

  return (
    <div id='agb'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 text-center'>
            <h2>AGB's</h2>
            <i className='fa fa-arrow-down' onClick={toggle}></i>
        </div>
      </div>
      {isOpened && (
        <div className='text-center conditions'>
          <div className="text-conditions">
            <p>
              <h3>Versicherung und Haftung</h3>

              Die Teilnahme erfolgt freiwillig und auf eigenes Risiko.
              Brigä's Hundeschule setzt voraus, dass Sie eine obligatorische Haftpflichtversicherung für Personen und Sachschäden mit "Deckung Hund" abgeschlossen haben.
              Brigä's Hundeschule übernimmt keine Haftung für Unfälle, Personen, Sach- und Vermögensschäden, die bei Trainings, Workshops oder Seminaren entstehen, sowie für Schäden und Verletzungen jeglicher Art, die durch teilnehmende Hunde entstehen.
              Der Kunde übernimmt die alleinige Haftung für seinen Hund und für seine Begleitpersonen, auch wenn er auf Veranlassung von Brigä's Hundeschule handelt.
              Vom Teilnehmer beschädigtes oder verloren gegangenes Material muss ersetzt werden.

              <h3>Impfung und Krankheiten</h3>

              Der Kunde versichert, dass sein Hund frei von ansteckenden Krankheiten ist.
              Ein Impfausweis ist auf Verlangen der Kursleitung vorzuweisen.
              Hunde mit ansteckenden Krankheiten/Schmerzen können von Brigä's Hundeschule vom Kurs, Workshops oder Seminar ausgeschlossen werden.
              Der Hund muss einen Mikrochip tragen und bei AMICUS angemeldet sein.

              <h3>Kursdurchführung</h3>

              Mit der schriftlichen Anmeldung (SMS, Mail etc.) an einem Angebot von Brigä's Hundeschule durch den Teilnehmer, ist der Vertrag verbindlich und der Teilnehmer verpflichtet sich zur Zahlung der Kosten.
              Die Trainings, Kurse und Seminare finden bei jedem Wetter statt. Bei sehr schlechten Wetterverhältnissen können diese jedoch durch Brigä's Hundeschule verschoben oder abgesagt werden.
              Brigä's Hundeschule behält sich das Recht vor, ein Seminar zu verschieben oder nicht durchzuführen. Bereits bezahlte Kosten werden im Fall einer Nichtdurchführung zu 100% zurückerstattet.

              <h3>Annullationen</h3>

              Sind bis spätestens 7 Tage vor Kurs-oder Seminarbeginn mitzuteilen.
              Bei den Gruppenstunden sind Absagen spätestens 24 Stunden vorher schriftlich zu melden.
              Bei verspäteter nichtbegründeter Abmeldung oder Nichterscheinen werden die gesamten Kosten in Rechnung gestellt.
              Notfälle und Krankheiten sind ausgeschlossen.

              <h3>Film- und Fotoaufnahmen</h3>

              Für sämtliche Film- und Fotoaufnahmen, die während den Kursen von Brigä's Hundeschule gemacht werden, gilt ein Recht zum Gebrauch und Veröffentlichung durch Brigä's Hundeschule.
              Teilnehmende, die damit nicht einverstanden sind, müssen dies bis spätestens vor Kursbeginn melden.

              <h3>Sonstiges</h3>

              Die Teilnahme von läufigen Hündinnen ist nur nach vorheriger Absprache möglich.
              Alle Hunde sind während der Kurszeiten stets an der Leine zu führen, wenn die Kursleitung keine anders lautende Anweisung gibt.
              Im Interesse aller Seminarteilnehmer wird um pünktliches Erscheinen gebeten.
              Auf dem gesamten Trainingsgelände gilt Kotaufnahmepflicht.
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
