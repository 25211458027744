export const Team = (props) => {
  return (
    <div id='team'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title text-center'>
          <h2>Ausbildung</h2>
        </div>
        <div id='row'>
          {props.data
            ? props.data.person.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 col-sm-6 team  text-center'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='team-img' />
                    <div className='caption'>
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <div className="about-text">
                    <div className="list-style">
                      <div className="col-lg-6 col-sm-6 col-xs-12">
                        <ul>
                          {props.data
                            ? props.data.education.map((d, i) => (
                                <li key={`${d}-${i}`}>{d}</li>
                              ))
                            : "loading"}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}
