export const Philosophy = (props) => {
  return (
    <div id='philosophy' className='text-center'>
      <div className='container'>
        <div className="col-xs-12 col-md-12">
          <h2>Philosophie</h2>
          <p>{props.data ? props.data.paragraph : "loading..."}</p>
        </div>
      </div>
    </div>
  )
}
